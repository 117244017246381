import jwt_decode from 'jwt-decode';
import { find } from 'lodash';

export const DISPUTE_STATUS = Object.freeze({
    UNDER_REVIEW: 'Under Review',
    OPEN: 'Open',
    CLOSED: 'Closed',
});

export const sessionStorageKeys = {
    OTP_COUNTS: 'resendCounts',
    SELECTED_BILLER: 'selectedBiller',
    BILLER_CATEGORY: 'billerCategory',
    SELECTED_PAYMENT_MODE: 'selectedPaymentMode',
    CUSTOMER_DETAILS: 'customerDetails',
    NET_BANKING_LIST: 'netBankingList',
    CASH_FREE_HTML: 'cashFreeHtml',
    COMPLAINT_STATUS_RESPONSE: 'complaintStatusResponse',
    ALLOWED_PAYMENT_MODES: 'allowedPaymentModes',
    PAYMENT_PROCESS_RES: 'paymentProcessRes',
    BILL_COPY: 'bill_copy',
    BILL: 'bill',
    TRANSACTION_STATUS: 'transctionStatus',
    FROM_DEEPLINK: 'fromDeepLink',
    BILLER_TITLE: 'billerTitle',
    LOGIN_POPUP: 'loginPopup',
    EMAIL_POPUP: 'emailPopup',
    USER_DETAILS: 'userDetails',
    ACCESS_TOKEN: 'accessToken',
    IS_MOBILE: 'ismobile',
    VARIABLE_AMOUNT_INFO: 'variableAmountInfo',
    OPERATOR_PAGE_URL: 'operatorPageUrl',
    USER_PHONE: 'userPhone',
    LOGIN_FROM_DEEP_LINK: 'loginFromDeeplink',
    CREATE_ACC_HIT_URL: 'createAccountHitUrl',
    FLOW_TYPE: 'flowType',
    INITIATE_TRANSACTION_RESPONSE: 'initiateTransactionResponse',
    ORIGINAL_BILL: 'ORIGINAL_BILL',
    LOGIN_NUDGE_SHOW: 'LOGIN_NUDGE_SHOW',
    BIFURCATED_AMOUNT: 'bifurcatedAmount',
    REDIRECTION_FROM_FAQ: 'redirectionFromFaq',
    IS_MANDATORY_LOGIN: 'isMandatoryLogin',
    IS_AUTO_LOGIN: 'isAutoLogin',
    SHOW_LOGIN_CTA: 'showLoginCta',
    COMPANY_LOGO: 'companyLogo',
    PWA_INSTALL_DRAWER: 'pwaInstallDrawer',
    TRUST_NAME: 'trustName',
    CREDITLINE_BIFURCATION: 'creditlineBifurcation',
    COMPANY_TYPE: 'companyType',
    AMOUNT_TYPE: 'amountType',
    USER_SELECTED_CREDITLINE_DATA: 'userSelectedApiData',
    CREDITLINE_RECOVERED: 'creditlineRecovered',
    CREDITLINE_BIFURCATION_RECOVERED: 'creditlineBifurcationRecovered',
    MANUAL_CHECKED_KEYS: 'manualCheckedKeys',
    MANUAL_CHECKED_AMOUNT: 'manualCheckedAmount',
    UPI_TRAY_URL: 'upiTrayUrl',
    COMPANY_ID: 'companyId',
    EASY_SETTLE_DATA: 'easy_settle',
};

export const checkUserLoggedIn = () =>
    sessionStorage.getItem(sessionStorageKeys.ACCESS_TOKEN) &&
    sessionStorage.getItem(sessionStorageKeys.ACCESS_TOKEN) != 1;

export const localStorageKeys = {
    CREATE_BACK: 'createBack',
    INFO_ALERT: 'infoAlert',
    TRANSACTION_FILTER: 'filter',
    TRANSACTION_DATE_FILTER: 'dateFilter',
    ACCESS_TOKEN: 'accessToken',
    USER_DETAILS: 'userDetails',
    USER_PHONE: 'userPhone',
    SECOND_USER_PHONE: 'secondUserPhone',
    SECOND_USER_TOKEN: 'secondUserToken',
};

export const intervalDurationMapping = {
    days: 'Daily',
    weeks: 'Weekly',
    months: 'Monthly',
    'on-demand': 'On Demand',
};

export const transactionStatus = {
    PENDING: 'PENDING',
    INITIATED: 'PENDING',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
};

export const clearUserDataFromStorage = () => {
    localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(localStorageKeys.USER_DETAILS);
    localStorage.removeItem(localStorageKeys.USER_PHONE);
    localStorage.removeItem(localStorageKeys.SECOND_USER_PHONE);
    localStorage.removeItem(localStorageKeys.SECOND_USER_TOKEN);
    sessionStorage.clear();
};

export const paymentProgressStatus = {
    1: 'CG_INITIATED',
    101: 'PG_INITIATED',
    125: 'PG_FAILED',
    150: 'PG_PENDING',
    199: 'PG_SUCCESS',
    201: 'BBPS_INITIATED',
    225: 'BBPS_FAILED',
    250: 'BBPS_PENDING',
    299: 'BBPS_SUCCESS',
    301: 'SETTLE_INITIATED',
    325: 'SETTLE_FAILED',
    350: 'SETTLE_PENDING',
    399: 'SETTLE_SUCCESS',
};

// custom field keys names which are added dynamically

export const customFieldsKeys = {
    APPLICATION_PAYMENT_TYPE: 'Application Payment Type',
    ANNEXURE_ID: 'Annexure ID',
};
export const formateNetBakingList = (netBankingList) => {
    return netBankingList.map((item) => {
        return {
            id: item.id,
            label: item.name,
        };
    });
};

export const formateNonBbpsPaymentMethods = (modes) => {
    var data = [];

    modes.forEach((modeItem) =>
        data.push(modeItem?.replace(/_/g, '')?.toLowerCase())
    );
    return data;
};

export const formatePaymentMethods = (
    paymentModesAllowed = [],
    payment_modes = []
) => {
    let pgChargesMethods = payment_modes || [];
    let formattedpgChargesMethodsList = pgChargesMethods.map((methodItem) => {
        return methodItem?.replace(/_/g, '').toLowerCase();
    });
    let formattedPaymentModes = [];
    paymentModesAllowed.forEach((item) => {
        const paymentModeItem = item.paymentMode
            ?.toLowerCase()
            ?.replace(/ /g, '');
        if (formattedpgChargesMethodsList.indexOf(paymentModeItem) != -1)
            formattedPaymentModes.push(paymentModeItem);
    });
    return formattedPaymentModes;
};

export const formateCusomerParams = (
    customerParams,
    originalCustomerParams = []
) => {
    let result = [];

    result = originalCustomerParams.map((item) => {
        if (customerParams[item.paramName]) {
            item['value'] = customerParams[item.paramName];
        } else {
            item['value'] = '';
        }
        return item;
    });

    return result;
};

export const generateBillInfoResFromTransctionData = (response) => {
    const data = {};
    const { amount, additionalParams, accountHolderName, dueDate, enc_refId } =
        response;
    data['amount'] = amount;
    data['additionalParams'] = additionalParams;
    data['accountHolderName'] = accountHolderName;
    data['dueDate'] = dueDate;
    data['enc_refId'] = enc_refId;
    return data;
};
export const generateNonBbpsres = (data) => {
    let formattedRes = {};
    const keys = Object.keys(data || {});
    keys?.forEach((keyItem) => {
        formattedRes[keyItem?.replace(/ /g, '')?.replace(/ /g, '')] =
            data[keyItem];
    });
    return formattedRes;
};

export const formateVariableAmountInfo = (response) => {
    return {
        min: response ? response.min_limit : 0,
        max: response ? response.max_limit : 0,
    };
};

export const iSiOS = () => {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

export const staticPagesList = [
    {
        text: 'Terms and Conditions',
        link: '#',
    },
    {
        text: 'Contact Us',
        link: '#',
    },
    {
        text: 'Privacy Policy',
        link: '#',
    },
];

export const decodeJWT = (token, fetchUserDetails = true) => {
    var decodedToken = jwt_decode(token);

    // localStorage.setItem(localStorageKeys.ACCESS_TOKEN, token);
    if (fetchUserDetails) {
        let userDetails =
            JSON.parse(localStorage.getItem(localStorageKeys.USER_DETAILS)) ||
            {};
        userDetails['fname'] = decodedToken['first_name'];
        userDetails['lname'] = decodedToken['last_name'];

        localStorage.setItem(
            localStorageKeys.USER_DETAILS,
            JSON.stringify(userDetails)
        );

        return userDetails;
    }
};

export const menuItemList = [
    {
        title: 'Loan Repayments',
        logo: '/loanRepayment.svg',
        apiKey: 'LOAN',
        selectedLogo: '/loanRepayment_selected.svg',
        borderedLogo: '/loanRepayment_bordered.svg',
        tagLine: 'Pay your Loan EMI',
        eventName: 'Home_LoanRepayments',
        isReleased: true,
    },
    {
        title: 'Prepaid Recharge',
        logo: '/V2_phoneLogo.svg',
        apiKey: 'MOBILE PREPAID',
        selectedLogo: '/V2_phoneLogo_selected.svg',
        tagLine: 'Recharge or Pay Mobile Bill',
        eventName: 'Home_PrepaidRecharge',
        isReleased: true,
    },
    {
        title: 'DTH',
        logo: '/v2_dthLogo.svg',
        apiKey: 'DTH',
        selectedLogo: '/v2_dthLogo_selected.svg',
        tagLine: 'DTH Recharge',
        eventName: 'Home_DTH',
        isReleased: true,
    },
    {
        title: 'Electricity',
        logo: '/v2_electricityLogo.svg',
        selectedLogo: '/v2_electricityLogo_selected.svg',
        apiKey: 'ELECTRICITY',
        tagLine: 'Electricity Bill Payment',
        eventName: 'Home_Electricity',
        isReleased: true,
    },
    {
        title: 'Broadband',
        logo: '/v2_broadbandLogo.svg',
        selectedLogo: '/v2_broadbandLogo_selected.svg',
        apiKey: 'BROADBAND POSTPAID', //@needs to be confirmed with @mukul and @kartic
        tagLine: 'Pay your Broadband bill',
        eventName: 'Home_Broadband',
        isReleased: true,
    },
    {
        title: 'Telecom (post)',
        logo: '/v2_telecomLogo.svg',
        selectedLogo: '/v2_telecomLogo_selected.svg',
        apiKey: 'MOBILE POSTPAID', //@needs to be confirmed with @mukul and @kartic
        tagLine: 'Pay your Telecom Bill',
        eventName: 'Home_Telecom',
        isReleased: true,
    },
    {
        title: 'Insurance',
        logo: '/v2_insuranceLogo.svg',
        selectedLogo: '/v2_insuranceLogo_selected.svg',
        apiKey: 'INSURANCE',
        tagLine: 'Pay your Insurance Premium',
        eventName: 'Home_Insurance',
        isReleased: true,
    },
    {
        title: 'Gas',
        logo: '/v2_gasLogo.svg',
        apiKey: 'GAS',
        selectedLogo: '/v2_gasLogo_selected.svg',
        tagLine: 'Pay Gas Bill',
        eventName: 'Home_Gas',
        isReleased: true,
    },
    {
        title: 'Water',
        logo: '/v2_waterLogo.svg',
        apiKey: 'WATER',
        selectedLogo: '/v2_waterLogo_selected.svg',
        tagLine: 'Pay Water Bill',
        eventName: 'Home_ Water',
        isReleased: true,
    },
    {
        title: 'Municipal taxes',
        logo: '/v2_houseLogo.svg',
        apiKey: 'ELECTRICITY',
        selectedLogo: '/v2_houseLogo_selected.svg',
        tagLine: 'Pay your Municipal Taxes',
        eventName: 'Home_Municipaltaxes',
        isReleased: true,
    },
    {
        title: 'Cable TV',
        logo: '/v2_cabletv.svg',
        apiKey: 'CABLE',
        selectedLogo: '/v2_cabletv_selected.svg',
        tagLine: 'Cable TV Recharge',
        eventName: 'Home_CableTV',
        isReleased: true,
    },
    {
        title: 'Educational Fee',
        logo: '/v2_educationLogo.svg',
        apiKey: 'EDUCATION_FEE',
        selectedLogo: '/v2_educationLogo_selected.svg',
        tagLine: 'Pay your education fees',
        eventName: 'Home_Educationalfee',
        isReleased: true,
    },

    {
        title: 'FASTag',
        logo: '/v2_fastTagLogo.svg',
        apiKey: 'FASTAG',
        selectedLogo: '/v2_fastTagLogo_selected.svg',
        tagLine: 'FASTag Recharge',
        eventName: 'Home_FastTag',
        isReleased: true,
    },
    {
        title: 'Housing society',
        logo: '/Housing_Society_frame.svg',
        apiKey: 'HOUSING SOCIETY',
        selectedLogo: '/v2_housingSocity_selected.svg',
        tagLine: 'Pay Housing Society Bill',
        eventName: 'Home_Housingsociety',
        isReleased: true,
    },
    {
        title: 'Subscription (OTT)',
        logo: '/v2_ottLogo.svg',
        selectedLogo: '/v2_ottLogo_selected.svg',
        apiKey: 'SUBSCRIPTION',
        tagLine: 'OTT Membership',
        eventName: 'Home_OTT',
        isReleased: true,
    },
    {
        title: 'LPG',
        logo: '/v2_lpgLogo.svg',
        apiKey: 'LPG GAS',
        selectedLogo: '/v2_lpgLogo_selected.svg',
        tagLine: 'LPG Premium',
        eventName: 'Home_LPG',
        isReleased: true,
    },
];

export const getSelectedCategroyItem = (apiKey) => {
    return find(menuItemList, function (menuItem) {
        return menuItem.apiKey == apiKey;
    });
};

export const getStatusBasisStatusCode = (statusCode) => {
    let code = Number(statusCode || '0');
    if (code >= 299) return 'success';
    else if (code === 125 || code === 225 || code === 120) return 'failed';
    else return 'pending';
};

export const getRefundStatusBasisStatusCode = (refundCode) => {
    if (refundCode == 450 || refundCode == 401) return 'refund pending';
    else if (refundCode == 499) return 'refund success';
    else if (refundCode == 425) return 'refund failed';
};

export const checkPostpaidCategory = (selectedCategoryItem = {}) =>
    selectedCategoryItem?.apiKey === 'MOBILE POSTPAID';

export const getFormattedDate = (date) => {
    let newDate = new Date(date);
    const months = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
    ];
    return (
        newDate.getDate() +
        ' ' +
        months[newDate.getMonth()] +
        ', ' +
        newDate.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        })
    );
};

export const getUrlQueyParam = (url = window.location.href) => {
    var urlData = new URL(url);
    return new URLSearchParams(urlData.search);
};

export const getSvgForSideDrawer = (type, activeType) => {
    if (type === 'home') {
        return (
            <svg
                width="14"
                height="14"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_1287_45520)">
                    <path
                        d="M14.7749 7.3823L8.30615 0.916895L7.87256 0.483301C7.77376 0.385159 7.64016 0.330078 7.50091 0.330078C7.36165 0.330078 7.22805 0.385159 7.12925 0.483301L0.226911 7.3823C0.125679 7.48313 0.0456746 7.60323 -0.0083813 7.73549C-0.0624372 7.86776 -0.0894486 8.00951 -0.0878212 8.15239C-0.0811248 8.74167 0.409388 9.2121 0.998674 9.2121H1.71017V14.6647H13.2916V9.2121H14.0182C14.3045 9.2121 14.574 9.09993 14.7766 8.89736C14.8763 8.79794 14.9553 8.67972 15.0091 8.54954C15.0628 8.41937 15.0902 8.27982 15.0896 8.13899C15.0896 7.85439 14.9775 7.58486 14.7749 7.3823ZM8.43841 13.4593H6.56341V10.0441H8.43841V13.4593ZM12.0863 8.00674V13.4593H9.50983V9.64234C9.50983 9.27236 9.21017 8.9727 8.84019 8.9727H6.16162C5.79164 8.9727 5.49198 9.27236 5.49198 9.64234V13.4593H2.91553V8.00674H1.30838L7.50258 1.81756L7.8893 2.20428L13.6951 8.00674H12.0863Z"
                        fill={!type === activeType ? '#4164ab' : '#1890FF'}
                        fill-opacity="0.85"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1287_45520">
                        <rect width="15" height="15" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    } else if (type === 'mandate') {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
            >
                <path
                    d="M3.625 2.90332C3.55625 2.90332 3.5 2.95957 3.5 3.02832V3.77832C3.5 3.84707 3.55625 3.90332 3.625 3.90332H9.625C9.69375 3.90332 9.75 3.84707 9.75 3.77832V3.02832C9.75 2.95957 9.69375 2.90332 9.625 2.90332H3.625ZM6.5 5.15332H3.625C3.55625 5.15332 3.5 5.20957 3.5 5.27832V6.02832C3.5 6.09707 3.55625 6.15332 3.625 6.15332H6.5C6.56875 6.15332 6.625 6.09707 6.625 6.02832V5.27832C6.625 5.20957 6.56875 5.15332 6.5 5.15332ZM5.75 12.3096H2.25V1.30957H11V6.30957C11 6.37832 11.0563 6.43457 11.125 6.43457H12C12.0688 6.43457 12.125 6.37832 12.125 6.30957V0.68457C12.125 0.408008 11.9016 0.18457 11.625 0.18457H1.625C1.34844 0.18457 1.125 0.408008 1.125 0.68457V12.9346C1.125 13.2111 1.34844 13.4346 1.625 13.4346H5.75C5.81875 13.4346 5.875 13.3783 5.875 13.3096V12.4346C5.875 12.3658 5.81875 12.3096 5.75 12.3096ZM12.625 10.9346H10.375V10.3627C11.0984 10.1471 11.625 9.47832 11.625 8.68457C11.625 7.71738 10.8422 6.93457 9.875 6.93457C8.90781 6.93457 8.125 7.71738 8.125 8.68457C8.125 9.47676 8.65156 10.1471 9.375 10.3627V10.9346H7.125C6.9875 10.9346 6.875 11.0471 6.875 11.1846V13.5596C6.875 13.6971 6.9875 13.8096 7.125 13.8096H12.625C12.7625 13.8096 12.875 13.6971 12.875 13.5596V11.1846C12.875 11.0471 12.7625 10.9346 12.625 10.9346ZM9.09375 8.68457C9.09375 8.25332 9.44375 7.90332 9.875 7.90332C10.3063 7.90332 10.6563 8.25332 10.6563 8.68457C10.6563 9.11582 10.3063 9.46582 9.875 9.46582C9.44375 9.46582 9.09375 9.11582 9.09375 8.68457ZM11.9063 12.8408H7.84375V11.9033H11.9063V12.8408Z"
                    fill={!type === activeType ? '#4164AB' : '#1890FF'}
                />
            </svg>
        );
    } else if (type === 'transaction') {
        return (
            <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.3893 7.97727H0.152378C0.0686843 7.97727 0.000207845 8.04375 0.000207845 8.125V9.23295C0.000207845 9.3142 0.0686843 9.38068 0.152378 9.38068H11.664L8.91927 12.7599C8.84128 12.856 8.91166 13 9.0391 13H10.4181C10.5113 13 10.5988 12.9594 10.6578 12.8874L13.8686 8.93381C14.1824 8.54602 13.899 7.97727 13.3893 7.97727ZM13.8477 3.61932H2.33602L5.08078 0.240057C5.15877 0.144034 5.08839 0 4.96095 0H3.58191C3.4887 0 3.40121 0.0406251 3.34224 0.112642L0.131454 4.06619C-0.182396 4.45398 0.10102 5.02273 0.608887 5.02273H13.8477C13.9314 5.02273 13.9998 4.95625 13.9998 4.875V3.76705C13.9998 3.6858 13.9314 3.61932 13.8477 3.61932Z"
                    fill={!type === activeType ? '#4164AB' : '#1890FF'}
                />
            </svg>
        );
    } else if (type === 'complaint') {
        return (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.40499 3.1093H6.59755C6.52368 3.1093 6.46325 3.16791 6.46325 3.23954V7.72116C6.46325 7.76349 6.4834 7.80256 6.51865 7.82698L9.29349 9.79186C9.35392 9.83419 9.43786 9.82279 9.4815 9.76419L9.9616 9.1293C10.0069 9.06907 9.9935 8.98767 9.93306 8.94698L7.53928 7.26861V3.23954C7.53928 3.16791 7.47885 3.1093 7.40499 3.1093ZM11.0947 4.33349L13.7269 4.95698C13.8108 4.97651 13.893 4.91465 13.893 4.83163L13.9065 2.20256C13.9065 2.09349 13.7772 2.03163 13.6899 2.1L11.0443 4.10396C11.0243 4.11896 11.0091 4.13912 11.0004 4.16214C10.9917 4.18516 10.9899 4.21009 10.9952 4.23407C11.0004 4.25804 11.0126 4.2801 11.0302 4.29768C11.0478 4.31527 11.0701 4.32768 11.0947 4.33349ZM13.9098 9.23512L12.958 8.91768C12.9248 8.90664 12.8885 8.90864 12.8568 8.92325C12.8252 8.93785 12.8006 8.9639 12.7885 8.99581C12.7566 9.07884 12.723 9.16023 12.6878 9.24163C12.389 9.92698 11.9609 10.544 11.4137 11.073C10.8725 11.5995 10.2311 12.0191 9.52515 12.3086C8.79379 12.6085 8.00765 12.7624 7.21362 12.7612C6.41121 12.7612 5.63399 12.6098 4.90209 12.3086C4.19611 12.0191 3.55476 11.5995 3.01359 11.073C2.46802 10.544 2.03996 9.92698 1.73948 9.24163C1.43197 8.532 1.27442 7.76974 1.27616 7C1.27616 6.22186 1.43228 5.46651 1.74283 4.75675C2.04164 4.0714 2.4697 3.45442 3.01694 2.92535C3.55811 2.39892 4.19946 1.9793 4.90545 1.68977C5.63399 1.38861 6.41289 1.23721 7.2153 1.23721C8.0177 1.23721 8.79492 1.38861 9.52682 1.68977C10.2328 1.9793 10.8742 2.39892 11.4153 2.92535C11.5866 3.09302 11.7477 3.26721 11.8954 3.45116L12.8993 2.6893C11.5782 1.05163 9.52179 -0.00162602 7.21194 1.88437e-06C3.18985 0.00162979 -0.0399152 3.16954 0.000372853 7.07163C0.0406609 10.9053 3.25531 14 7.2153 14C10.3292 14 12.9815 12.0856 13.9921 9.40442C14.0173 9.33605 13.9803 9.25954 13.9098 9.23512Z"
                    fill={!type === activeType ? '#4164AB' : '#1890FF'}
                />
            </svg>
        );
    } else if (type === 'chargeback') {
        return (
            <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.2024 6.38612C10.3384 6.38612 10.4688 6.33262 10.565 6.23739C10.6611 6.14216 10.7151 6.01299 10.7151 5.87832C10.7151 5.74364 10.6611 5.61448 10.565 5.51925C10.4688 5.42401 10.3384 5.37051 10.2024 5.37051H6.35729C6.22132 5.37051 6.09091 5.42401 5.99477 5.51925C5.89862 5.61448 5.8446 5.74364 5.8446 5.87832C5.8446 6.01299 5.89862 6.14216 5.99477 6.23739C6.09091 6.33262 6.22132 6.38612 6.35729 6.38612H7.45956C7.6542 6.39001 7.84538 6.43775 8.0186 6.52574C8.19183 6.61373 8.34254 6.73964 8.4593 6.89392H6.35729C6.22132 6.89392 6.09091 6.94742 5.99477 7.04265C5.89862 7.13789 5.8446 7.26705 5.8446 7.40173C5.8446 7.5364 5.89862 7.66557 5.99477 7.7608C6.09091 7.85603 6.22132 7.90953 6.35729 7.90953H8.4593C8.34254 8.06381 8.19183 8.18973 8.0186 8.27771C7.84538 8.3657 7.6542 8.41345 7.45956 8.41733H6.35729C6.25493 8.42917 6.1578 8.46856 6.07645 8.53123C5.99511 8.5939 5.93265 8.67744 5.89587 8.77279C5.85271 8.86926 5.83941 8.97624 5.85766 9.08019C5.87592 9.18415 5.9249 9.2804 5.99841 9.35677L8.74128 11.9466C8.83594 12.0422 8.96493 12.097 9.10016 12.0989C9.17393 12.1019 9.24736 12.0873 9.31427 12.0564C9.38118 12.0255 9.43961 11.9791 9.48467 11.9212C9.57527 11.823 9.62334 11.6935 9.61854 11.5605C9.61375 11.4276 9.55648 11.3018 9.45904 11.2102L7.639 9.5345C8.56184 9.45833 9.33087 8.67123 9.56158 7.90953H10.2024C10.3384 7.90953 10.4688 7.85603 10.565 7.7608C10.6611 7.66557 10.7151 7.5364 10.7151 7.40173C10.7151 7.26705 10.6611 7.13789 10.565 7.04265C10.4688 6.94742 10.3384 6.89392 10.2024 6.89392H9.56158C9.51031 6.64002 9.40777 6.38612 9.30523 6.38612H10.2024Z"
                    fill="#4164AB"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.57041 6.89738L1.51857 8.65658C1.45297 8.71238 1.34923 8.69775 1.30879 8.62672L0.0161687 6.3836C-0.0369583 6.29028 0.0485842 6.17716 0.160005 6.19501L3.50367 6.67754C3.52887 6.68106 3.55232 6.69123 3.57131 6.70687C3.59031 6.72251 3.60409 6.74299 3.61106 6.76596C3.61803 6.78892 3.61791 6.81344 3.61072 6.83669C3.60353 6.85994 3.58956 6.88097 3.57041 6.89738ZM3.43867 12.4021L3.43927 12.4014V12.5777C3.3914 12.53 3.39011 12.453 3.43867 12.4021ZM13.5004 4.34964C13.1419 3.79234 12.6876 3.30653 12.1456 2.90448C11.5444 2.45726 10.8608 2.12873 10.1134 1.93091C9.37265 1.73252 8.59431 1.67218 7.82149 1.75322C7.02282 1.83483 6.25203 2.06802 5.53395 2.44169C4.81587 2.81535 4.19407 3.30683 3.68578 3.90533C3.19502 4.48181 2.82546 5.13949 2.59758 5.84191C2.52603 6.06511 2.46665 6.28919 2.42406 6.51536L1.15461 6.33101C1.53919 4.31463 2.86642 2.45586 4.93432 1.38159C8.53452 -0.490041 12.9679 0.716302 14.8325 4.07361C14.8833 4.16499 14.9315 4.25702 14.9774 4.34964H13.5004Z"
                    fill="#4164AB"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.5956 9.10262L15.6907 7.34342C15.7577 7.28762 15.8637 7.30225 15.9049 7.37328L17.2248 9.6164C17.2791 9.70972 17.1917 9.82284 17.078 9.80499L13.6638 9.32246C13.6381 9.31894 13.6141 9.30877 13.5947 9.29313C13.5753 9.27749 13.5612 9.25701 13.5541 9.23404C13.547 9.21108 13.5471 9.18656 13.5545 9.16331C13.5618 9.14006 13.5761 9.11903 13.5956 9.10262ZM13.7302 3.59793L13.7296 3.59851V3.42235C13.7784 3.47007 13.7797 3.547 13.7302 3.59793ZM3.45623 11.6504C3.82226 12.2077 4.28619 12.6935 4.83958 13.0955C5.45349 13.5427 6.1515 13.8713 6.91471 14.0691C7.67104 14.2675 8.4658 14.3278 9.25491 14.2468C10.0704 14.1652 10.8575 13.932 11.5907 13.5583C12.3239 13.1846 12.9588 12.6932 13.4778 12.0947C13.9789 11.5182 14.3563 10.8605 14.589 10.1581C14.662 9.93489 14.7227 9.71081 14.7662 9.48463L16.0624 9.66899C15.6697 11.6854 14.3145 13.5441 12.203 14.6184C8.52685 16.49 3.99996 15.2837 2.09602 11.9264C2.04422 11.835 1.99493 11.743 1.94812 11.6504H3.45623Z"
                    fill="#4164AB"
                />
            </svg>
        );
    } else if (type === 'profile') {
        return (
            <svg
                width="13"
                height="14"
                viewBox="0 0 13 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.49 11.4251C12.1639 10.6392 11.6906 9.92541 11.0966 9.32337C10.5044 8.71959 9.80293 8.2382 9.03083 7.90578C9.02392 7.90226 9.017 7.9005 9.01009 7.89698C10.0871 7.10553 10.7872 5.81633 10.7872 4.36181C10.7872 1.95226 8.86833 0 6.5 0C4.13166 0 2.21279 1.95226 2.21279 4.36181C2.21279 5.81633 2.91292 7.10553 3.98991 7.89874C3.983 7.90226 3.97608 7.90402 3.96917 7.90754C3.1947 8.23995 2.49976 8.71658 1.90336 9.32513C1.3099 9.92763 0.836747 10.6414 0.510013 11.4269C0.18903 12.1959 0.0159161 13.0204 4.32282e-05 13.8558C-0.000418174 13.8745 0.00281802 13.8932 0.00956122 13.9107C0.0163044 13.9282 0.0264181 13.9441 0.0393064 13.9576C0.0521947 13.971 0.0675968 13.9817 0.0846052 13.989C0.101614 13.9963 0.119884 14 0.13834 14H1.17557C1.25163 14 1.31214 13.9384 1.31386 13.8628C1.34844 12.505 1.88434 11.2334 2.83167 10.2696C3.81185 9.27236 5.11357 8.72362 6.5 8.72362C7.88643 8.72362 9.18815 9.27236 10.1683 10.2696C11.1157 11.2334 11.6516 12.505 11.6861 13.8628C11.6879 13.9402 11.7484 14 11.8244 14H12.8617C12.8801 14 12.8984 13.9963 12.9154 13.989C12.9324 13.9817 12.9478 13.971 12.9607 13.9576C12.9736 13.9441 12.9837 13.9282 12.9904 13.9107C12.9972 13.8932 13.0004 13.8745 13 13.8558C12.9827 13.0151 12.8115 12.1972 12.49 11.4251ZM6.5 7.38693C5.70652 7.38693 4.95972 7.07211 4.39789 6.5005C3.83605 5.92889 3.52662 5.1691 3.52662 4.36181C3.52662 3.55452 3.83605 2.79472 4.39789 2.22312C4.95972 1.65151 5.70652 1.33668 6.5 1.33668C7.29348 1.33668 8.04028 1.65151 8.60211 2.22312C9.16394 2.79472 9.47338 3.55452 9.47338 4.36181C9.47338 5.1691 9.16394 5.92889 8.60211 6.5005C8.04028 7.07211 7.29348 7.38693 6.5 7.38693Z"
                    fill={!type === activeType ? '#4164AB' : '#1890FF'}
                />
            </svg>
        );
    }
};
export const paymentMethodType = {
    upi: 'upi',
    internetbanking: 'internetbanking',
    debitcard: 'debitcard',
    creditCard: 'creditCard',
};

export const paymentMethodTypeLabel = {
    upi: 'UPI',
    internetbanking: 'Internet Banking',
    debitcard: 'Debit Card',
    creditCard: 'Credit Card',
};

export const makePaymentDetailList = (paymentMethods = [], amount = 0) => {
    const list = paymentMethods?.map((val) => {
        if (val === paymentMethodType?.upi) {
            return {
                label: paymentMethodTypeLabel?.upi,
                id: '2',
                image: '/upi.svg',
                selectedImage: '/upiMethodSelected.svg',
                isDisabled: amount >= 100000,
            };
        }
        if (val === paymentMethodType?.debitcard) {
            return {
                label: paymentMethodTypeLabel?.debitcard,
                id: '4',
                selectedImage: '/debitCardSelected.svg',
                image: '/debitCard.svg',
                isDisabled: false,
            };
        }
        if (val === paymentMethodType?.internetbanking) {
            return {
                label: paymentMethodTypeLabel?.internetbanking,
                id: '3',
                selectedImage: '/netBankingSelected.svg',
                image: '/netBanking.svg',
                isDisabled: false,
            };
        }
    });

    return list ?? [];
};

//function for allowing only 2 decimal places
export const decimalCheckHelper = (val) => {
    if (val?.indexOf('.') > 0) {
        let decimalVal = val?.substring(val?.indexOf('.') + 1);
        if (decimalVal?.length > 2) return true;
    }
    return false;
};

export const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // iOS detection
    if (/android/i.test(userAgent)) {
        return 'Android';
    }
    // iOS detection
    if (/iPad|iPhone|iPod|iMac/.test(userAgent) && !window.MSStream) {
        return 'IOS';
    }

    return 'unknown';
};

//helper function for returning the colour on the basis of Dispute Status
export const disputeStatusColour = (status) => {
    switch (status) {
        case DISPUTE_STATUS.UNDER_REVIEW:
            return '#FEB751';
        case DISPUTE_STATUS.CLOSED:
            return '#1890FF';
        case DISPUTE_STATUS.OPEN:
            return '#F34D29';
        default:
            return '';
    }
};

//helper function for returning the background-colour on the basis of Dispute Status
export const disputeStatusBackground = (status) => {
    switch (status) {
        case DISPUTE_STATUS.UNDER_REVIEW:
            return 'rgb(251 230 212 / 37%)';
        case DISPUTE_STATUS.CLOSED:
            return '#d4eaff8c';
        case DISPUTE_STATUS.OPEN:
            return 'rgb(251 211 211 / 19%)';
        default:
            return '';
    }
};
