import forge from 'node-forge';
const key = forge.util.hexToBytes(process.env.NEXT_PUBLIC_ENCRYPTION_KEY);
const iv = forge.util.hexToBytes(process.env.NEXT_PUBLIC_ENCRYPTION_IV);
// Function to encrypt the mobile number
const encryptMobileNumber = (mobileNumber) => {
    const cipher = forge?.cipher?.createCipher('AES-CBC', key);
    cipher?.start({ iv: iv });
    const mobileNumberBytes = forge?.util?.createBuffer(mobileNumber, 'utf8');
    cipher?.update(mobileNumberBytes);
    cipher?.finish();
    const encrypted = cipher?.output?.getBytes();
    return forge?.util?.encode64(encrypted);
};
// Function to decrypt the mobile numbder
const decryptMobileNumber = (encryptedMobileNumber) => {
    const decipher = forge?.cipher?.createDecipher('AES-CBC', key);
    decipher?.start({ iv: iv });
    const encryptedBytes = forge?.util?.decode64(encryptedMobileNumber); // Base64 decode the input
    decipher?.update(forge?.util?.createBuffer(encryptedBytes));
    decipher?.finish();
    return decipher?.output?.toString('utf8'); // Convert the decrypted output to string
};

// Storing encrypted data in localStorage
export const storeEncryptedNumber = (key, mobileNumber) => {
    const encryptedMobileNumber = encryptMobileNumber(mobileNumber);
    localStorage.setItem(key, encryptedMobileNumber);
};

// Retrieving and decrypting from localStorage
export const getDecryptedMobileNumber = (key) => {
    const encryptedMobileNumber = localStorage.getItem(key);
    if (encryptedMobileNumber) {
        return decryptMobileNumber(encryptedMobileNumber);
    }
    return null;
};
