var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"w0XpE5K72-Aw-jD743yoe"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const IgnoredSentryErrors = [
    {
        message:
            "SecurityError: Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document.",
        type: 'SecurityError',
    },
];

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://368e1d4daeed495b9142ce85a373c6f2@o1166858.ingest.sentry.io/6343215',
    beforeSend(event, hint) {
        let rejectEvent;
        const { exception: SentryExceptions } = event || {};
        (SentryExceptions?.values || [])?.forEach?.((exception) => {
            const { value } = exception || {};
            if (
                IgnoredSentryErrors.some(
                    (error) =>
                        !!value &&
                        error.message
                            ?.toLowerCase()
                            ?.includes(value?.toLowerCase())
                )
            )
                rejectEvent = true;
        });
        return rejectEvent ? null : event;
    },
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: process.env.NEXT_PUBLIC_RELEASE,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
