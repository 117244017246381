import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

//antd imports
import { Drawer } from 'antd';

//custom component imports
import Button from '../Button/CustomButton';

//helper functions imports
import { ReduxPropsSideDrawer, SideDrawerProps } from '../../helpers/interface';
import { getSvgForSideDrawer, sessionStorageKeys } from '../../utils';
import { SideBarMenuItems, formateName } from '../../helpers/constants';

//css imports
import drawer from './SideDrawer.module.scss';
import styles from '../../pages/v2/Layout.module.css';

const SideDrawer = ({
    onClickLoginBtn,
    userLoggedIn,
    showSideDrawer,
    closeSideDrawer,
    onClickEmailAdd,
}: SideDrawerProps) => {
    const router = useRouter();
    const {
        userObj = {},
        userPhone = '',
        userEmail = '',
    } = useSelector((state: ReduxPropsSideDrawer) => state?.payments);

    //helper function for getting the selection of the active menu
    const getActiveTab = (item): boolean =>
        router?.pathname?.indexOf(item) > -1;

    //helper function for redirecting to different pages through sidebar
    const redirectionHelper = (type: string, url: string = '') => {
        switch (type) {
            case 'home':
            case 'transaction':
            case 'mandate':
            case 'complaint':
            case 'chargeback':
            case 'profile':
                router.push(url);
                closeSideDrawer();
                break;
            case 'account':
                sessionStorage.setItem(
                    sessionStorageKeys.CREATE_ACC_HIT_URL,
                    window.location.href
                );
                window.location.href = `/v2/create-account?bckUrl=${window.location.pathname}`;
                break;
            default:
                break;
        }
    };

    return (
        <Drawer
            className="sideDrawer"
            title=""
            placement="right"
            onClose={closeSideDrawer}
            visible={showSideDrawer}
            bodyStyle={{
                padding: '0px',
                paddingTop: '66px',
                paddingBottom: '45px',
            }}
            height="450px"
            width="330px"
        >
            <div>
                {!userLoggedIn ? (
                    <div className={drawer?.sideDrawerUserProfile}>
                        <img className="" src="/drawerAvatar.svg"></img>
                        <div className={drawer?.sideDrawerTagLine}>
                            Stay on-track with your bill payments by logging in
                        </div>
                        <div className={drawer?.loginButtonWrapper}>
                            <Button
                                text="Login"
                                click={() => onClickLoginBtn(true)}
                            />
                        </div>

                        <div
                            className={`${drawer?.createAccountActionSideDrawer}`}
                        >
                            Don’t Have an account ?
                            <span
                                className={styles?.inLineAction}
                                onClick={() => redirectionHelper('account')}
                                style={{
                                    fontWeight: 'bold',
                                    paddingLeft: '4px',
                                }}
                            >
                                Create an account
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className={drawer?.sideDrawerUserProfile}>
                        <img className="" src="/drawerAvatar.svg"></img>
                        <div className={drawer?.sideDrawerUserName}>
                            {formateName(userObj)}
                        </div>
                        <div className={drawer?.sideDrawerPhoneNumber}>
                            {'+91 ' + userPhone}
                        </div>
                        <div className={drawer?.emailRow}>
                            {userEmail?.length ? (
                                <p className={drawer?.emailText}>{userEmail}</p>
                            ) : (
                                <>
                                    <p className={drawer?.emailText}>
                                        Email address
                                    </p>
                                    <p
                                        className={drawer?.emailEditText}
                                        onClick={onClickEmailAdd}
                                    >
                                        Add
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className="sideDrawerItems">
                    <ul className={drawer?.menuList}>
                        {Object.keys(SideBarMenuItems || {})?.map((item) => {
                            return (
                                <li
                                    key={SideBarMenuItems[item]?.label}
                                    className={`${drawer?.menuItem} ${
                                        getActiveTab(item) ? drawer?.active : ''
                                    } ${
                                        SideBarMenuItems[item]?.disabled &&
                                        !userLoggedIn
                                            ? drawer?.disabled
                                            : ''
                                    }`}
                                    onClick={() =>
                                        redirectionHelper(
                                            item,
                                            SideBarMenuItems[item]?.url
                                        )
                                    }
                                >
                                    <div
                                        className={drawer?.menuItemIconWrapper}
                                    >
                                        {getSvgForSideDrawer(
                                            item,
                                            getActiveTab(item)
                                        )}
                                        {SideBarMenuItems[item]?.label}
                                    </div>
                                    <img
                                        className={drawer?.openArrow}
                                        src="/openArrow.svg"
                                    ></img>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Drawer>
    );
};
export default SideDrawer;
